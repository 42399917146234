import React from 'react';
import ContactBox from '../../../components/contact-box/contact-box';

function NegoDetails(props) {

  // Directly use the negotiator or negotiator_details props to populate the ContactBox component
  const negotiatorName = props.negotiator ? props.negotiator.name : "Harringay Estate Agents";
  const negotiatorPhone = props.negotiator ? props.negotiator.telephone : "020 8341 9999";
  const negotiatorEmail = props.negotiator ? props.negotiator.email : "harringay@anthonypepe.com";

  return (
    <>
      <div>
        <ContactBox
          name={negotiatorName}
          phone={negotiatorPhone}
          email={negotiatorEmail}
          linkText="Video Call"
        />
      </div>
    </>
  );
}

export default NegoDetails;
